<template>
  <div>
    <div class="row">
      <div class="col-xl-4 col-lg-12">
        <div class="card" v-if="data">
          <div class="card-body">
            <profile-image-card :data="data" />
          </div>
        </div>
        <div
          class="card"
          v-if="
            data.entity &&
            data.entity.information &&
            data.entity.information.location
          "
        >
          <GmapMap
            map-type-id="roadmap"
            :zoom="15"
            :center="{
              lat: data.entity.information.location.latitude,
              lng: data.entity.information.location.longitude,
            }"
            style="width: 360px; height: 360px;"
          >
            <GmapMarker
              :position="{
                lat: data.entity.information.location.latitude,
                lng: data.entity.information.location.longitude,
              }"
              :draggable="true"
            />
          </GmapMap>
        </div>
        <div
          class="embed-responsive embed-responsive-16by9 card"
          v-if="
            data.entity &&
            data.entity.information &&
            data.entity.information.youtube_video_link
          "
        >
          <iframe
            width="560"
            height="315"
            :src="data.entity.information.youtube_video_link"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="col-xl-8 col-lg-12">
        <div class="card">
          <div class="card-header card-header-flex flex-column">
            <a-tabs
              defaultActiveKey="1"
              class="kit-tabs kit-tabs-bold"
              @change="callback"
            >
              <a-tab-pane tab="User Information" key="1" />
              <a-tab-pane
                tab="Information"
                key="2"
                v-if="data.user && data.user.user_type !== 'tourist'"
              />
              <a-tab-pane
                tab="Services"
                key="3"
                v-if="data.user && data.user.user_type !== 'tourist'"
              />
              <a-tab-pane
                tab="Media"
                key="4"
                v-if="data.user && data.user.user_type !== 'tourist'"
              />
            </a-tabs>
          </div>
          <div class="card-body">
            <div v-if="activeKey === '1'">
              <UserInformationTab
                v-if="data.user"
                :userInformation="data.user"
              />
            </div>
            <div v-if="activeKey === '2'">
              <div>
                <div class="mb-3">
                  <div class="table-responsive">
                    <InformationTab
                      v-if="data.entity && data.entity.information"
                      :information="data.entity.information"
                      :userType="this.$route.params.user_type"
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div v-if="activeKey === '3'">
              <div :class="$style.categories" class="row">
                <vue-custom-scrollbar style="height: 100%;" class="col-4">
                  <div class="d-flex flex-column">
                    <a
                      href="javascript: void(0);"
                      :class="[
                        $style.category,
                        selectedServiceIndex == index
                          ? `${$style.current}`
                          : '',
                      ]"
                      class="text-dark font-size-14 font-weight-bold text-wrap"
                      v-for="(service, index) in data.entity.services"
                      :key="index"
                      @click="showServiceData(index)"
                    >
                      <span class="text-truncate">{{ service.title }}</span>
                    </a>
                  </div>
                </vue-custom-scrollbar>
                <div class="col-8" v-if="data.entity.services.length">
                  <FoodAndDrinksServices
                    :serviceInformation="serviceInformation"
                    v-if="user_type === 'food_and_drinks'"
                  />
                  <TourOperatorServices
                    :serviceInformation="serviceInformation"
                    v-if="user_type === 'tour_operator'"
                  />
                  <TourSightServices
                    :serviceInformation="serviceInformation"
                    v-if="user_type === 'tour_sight'"
                  />
                  <AccommodationServices
                    :serviceInformation="serviceInformation"
                    v-if="user_type === 'hotel_and_accommodations'"
                  />
                  <TransportServices
                    :serviceInformation="serviceInformation"
                    v-if="user_type === 'transport'"
                  />
                  <EventsServices
                    :serviceInformation="serviceInformation"
                    v-if="user_type === 'events'"
                  />
                </div>
              </div>
            </div>
            <div v-if="activeKey === '4'">
              <MediaTab :media="data.entity.media" v-if="data.entity.media" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileImageCard from "./modules/profileImageCard/index";
import { mapActions, mapGetters } from "vuex";
import InformationTab from "./modules/informationTab";
import MediaTab from "./modules/mediaTab";
import UserInformationTab from "./modules/userInformationTab";
import vueCustomScrollbar from "vue-custom-scrollbar";
import FoodAndDrinksServices from "./modules/foodAndDrinksServices";
import TourOperatorServices from "./modules/tourOperatorServices";
import TourSightServices from "./modules/tourSightServices";
import AccommodationServices from "./modules/accommodationServices";
import TransportServices from "./modules/transportServices";
import EventsServices from "./modules/eventsService";
export default {
  components: {
    ProfileImageCard,
    InformationTab,
    vueCustomScrollbar,
    FoodAndDrinksServices,
    TourOperatorServices,
    TourSightServices,
    AccommodationServices,
    TransportServices,
    EventsServices,
    MediaTab,
    UserInformationTab,
  },
  data() {
    return {
      activeKey: "1",
      form: this.$form.createForm(this),
      ready: false,
      selectedServiceIndex: 0,
      center: { lat: 45.508, lng: -73.587 },
      markers: [{ lat: 45.508, lng: -73.587 }],
    };
  },
  async mounted() {
    await this.getAccount();
  },
  methods: {
    ...mapActions("admin", {
      getUser: "GET_USER",
    }),
    async getAccount() {
      try {
        const query = {
          user_type: this.user_type,
        };
        const id = this.$route.params.user_id;
        await this.getUser({ id, query });
      } catch (e) {
        console.log(e);
      } finally {
        this.ready = true;
      }
    },
    callback: function (key) {
      this.activeKey = key;
    },
    showServiceData(index) {
      this.selectedServiceIndex = index;
    },
  },
  computed: {
    ...mapGetters("admin", ["user"]),
    data: function () {
      return this.user || {};
    },
    serviceInformation: function () {
      return this.data.entity.services.length
        ? this.data.entity.services[this.selectedServiceIndex]
        : {};
    },
    user_type: function () {
      return this.$route.params.user_type;
    },
  },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
