<template>
    <div class="table-responsive" v-if="serviceInformation">
        <img v-if="serviceInformation.featured_image" :src="serviceInformation.featured_image" alt="Arktivetour" class="img-thumbnail"/>
        <table class="table table-borderless text-gray-6 mb-0">
            <tbody>
                <tr>
                <td>Title</td>
                <td>
                    <strong>{{serviceInformation.title || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Description</td>
                <td>
                    <strong>{{serviceInformation.description || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Price</td>
                <td>
                    <strong>{{serviceInformation.price || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Status</td>
                <td>
                    <strong>{{serviceInformation.status || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Available Tickets</td>
                <td>
                    <strong>{{serviceInformation.available_tickets || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Package Expiry</td>
                <td>
                    <strong>{{serviceInformation.package_expiry || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Departure Date</td>
                <td>
                    <strong>{{serviceInformation.departure_date || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Departure Time</td>
                <td>
                    <strong>{{serviceInformation.departure_time || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Arrival Date</td>
                <td>
                    <strong>{{serviceInformation.arrival_date || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Arrival Time</td>
                <td>
                    <strong>{{serviceInformation.arrival_time || ''}}</strong>
                </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
  props: {
    serviceInformation: {
      type: Object,
      required: true,
    },
  },
}
</script>
