<template>
  <div class="card">
    <div class="card-body">
      <div :class="$style.items">
        <div :class="$style.item" v-for="(item, index) in data" :key="index">
          <div :class="$style.itemContent">
            <div :class="$style.itemControl">
              <div :class="$style.itemControlContainer" v-if="showButton">
                <a-button-group>
                  <a-button icon="delete" @click="deleteImageHandler(item)" />
                </a-button-group>
              </div>
            </div>
            <img :src="item.path" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'featured-images-gallery',
  props: {
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    showButton: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  methods: {
    deleteImageHandler(data) {
      this.$emit('delete-image', data)
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
