<template>
    <div class="table-responsive" v-if="information">
        <table class="table table-borderless text-gray-6 mb-0">
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>
                        <strong>{{information.name || ''}}</strong>
                    </td>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>
                        <strong>{{information.description || ''}}</strong>
                     </td>
                </tr>
                <tr v-if="userType === 'tour_sight'">
                    <td>Attraction Type</td>
                    <td>
                        <strong>{{information.entity_type || ''}}</strong>
                    </td>
                </tr>
                <tr v-if="userType === 'tour_sight'">
                    <td>Business Registration Number</td>
                    <td>
                        <strong>{{information.business_registration_number || ''}}</strong>
                    </td>
                </tr>
                <tr v-if="userType === 'tour_sight'">
                    <td>Tourism Authority Number</td>
                    <td>
                        <strong>{{information.tourism_authority_number || ''}}</strong>
                    </td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>
                        <strong>{{information.email || ''}}</strong>
                    </td>
                </tr>
                <tr>
                    <td>Phone</td>
                    <td>
                        <strong>{{information.phones[0] ? information.phones[0].number : ""}}</strong>
                    </td>
                </tr>
                <tr>
                    <td>Website url</td>
                    <td @click="goToWebsite(information.website_url)" style="cursor:pointer" v-if="information.website_url">
                        <strong>{{information.website_url}}</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
  props: {
    information: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
  },
  methods: {
      goToWebsite(url) {
          window.open(url); 
      }
  },
}
</script>
