<template>
<div>
<div class="mb-3">
    <div class="table-responsive">
        <table class="table table-borderless text-gray-6 mb-0">
        <tbody>
            <tr>
            <td>First Name</td>
            <td>
                <strong>{{userInformation.first_name || ''}}</strong>
            </td>
            </tr>
            <tr>
            <td>Last Name</td>
            <td>
                <strong>{{userInformation.last_name || ''}}</strong>
            </td>
            </tr>
            <tr>
            <td>Email</td>
            <td>
                <strong>{{userInformation.email}}</strong>
            </td>
            </tr>
            <tr>
            <td>Phone</td>
            <td>
                <strong>{{userInformation.phone}}</strong>
            </td>
            </tr>
            <tr>
            <td>ID Type</td>
            <td>
                <strong>{{userInformation.id_type}}</strong>
            </td>
            </tr>
            <tr>
            <td>ID Number</td>
            <td>
                <strong>{{userInformation.id_number}}</strong>
            </td>
            </tr>
            <tr>
            <td>Country</td>
                <td>
                    <strong>{{userInformation.address ?userInformation.address.country.name : ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Registered</td>
                <td>
                    <strong>{{registeredDate(userInformation.created)}}</strong>
                </td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
    <div>
    </div>
</div>
</template>
<script>
import { formatDistance } from 'date-fns'
export default {
  props: {
    userInformation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    registeredDate(date) {
      const result = formatDistance(
        new Date(date),
        new Date(),
        { includeSeconds: true, addSuffix: true },
      )

      return result
    },
  },
}
</script>