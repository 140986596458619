<template>
<div class="row" >
      <Gallery
      :data="images"
      :showButton="false" />
</div>    
</template>
<script>
import Gallery from '@/components/shared/gallery/index.vue'
export default {
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  components: {
    Gallery
  },
  computed: {
    images(){
      const images = []
      const mediaKeys = ['logo', 'cover', 'featured_1', 'featured_2', 'featured_3']
      mediaKeys.forEach(key => images.push(this.media[key]))
      return images
    }
  }
}
</script>