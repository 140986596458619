<template>
  <div class="d-flex flex-wrap flex-column align-items-center">
    <div
      class="kit__utils__avatar kit__utils__avatar--size64 mb-3"
      v-if="data.user"
    >
      <img v-if="data.user && data.user.avatar" :src="data.user.avatar" />
      <img v-else src="@/assets/images/profile_image.png" />
    </div>
    <div class="text-center">
      <div
        class="text-dark font-weight-bold font-size-18"
        v-if="data.user && data.user.user_type"
      >
        {{ data.user.first_name }} {{ data.user.last_name }}
      </div>
      <div
        class="text-uppercase font-size-12 mb-3"
        v-if="data.user && data.user.user_type"
      >
        {{ removeUnderScore(data.user.user_type) }}
      </div>
      <div v-if="data.user && data.user.user_type !== 'tourist' && data.entity.meta">
        <button
          @click="accountApproval(true)"
          type="button"
          class="btn btn-primary"
          v-if="data.entity && !data.entity.meta.is_approved"
        >
          Approve
        </button>
        <button
          @click="accountApproval(false)"
          type="button"
          class="btn btn-danger"
          v-else
        >
          Unapprove
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "ProfileImageCard",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inactiveStatus: ["banned", "blocked"],
    };
  },
  methods: {
    ...mapActions("admin", {
      approveUser: "APPROVE_USER",
      getUser: "GET_USER",
    }),
    async getAccount() {
      try {
        const query = {
          user_type: this.user_type,
        };
        const id = this.$route.params.user_id;
        await this.getUser({ id, query });
      } catch (e) {
        console.log(e);
      } finally {
        this.ready = true;
      }
    },
    async accountApproval(approve) {
      await this.approveUser({
        id: this.data.user._id,
        user_type: this.user_type,
        approve,
      });
      await this.getAccount();
    },
    removeUnderScore(word) {
      const result = word.replace(/_/g, " ");
      return result;
    },
  },
  computed: {
    user_type: function () {
      return this.$route.params.user_type;
    },
  },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
