<template>
    <div class="table-responsive" v-if="serviceInformation">
        <div class="row">
            <div class="col-4" v-for="(serviceImage, index) in serviceInformation.featured_images" :key="index">
                <img :src="serviceImage.path" alt="Arktivetour" class="img-thumbnail"/>
            </div>
        </div>
        <table class="table table-borderless text-gray-6 mb-0">
            <tbody>
                <tr>
                <td>Vehicle Type</td>
                <td>
                    <strong>{{serviceInformation.vehicle_type || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Vehicle Model</td>
                <td>
                    <strong>{{serviceInformation.vehicle_model || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Vehicle Description</td>
                <td>
                    <strong>{{serviceInformation.vehicle_description || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Vehicle Year</td>
                <td>
                    <strong>{{serviceInformation.vehicle_year || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Duration Type</td>
                <td>
                    <strong>{{serviceInformation.duration_type || ''}}</strong>
                </td>
                </tr>
                <tr>
                <td>Status</td>
                <td>
                    <strong>{{serviceInformation.status || ''}}</strong>
                </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  props: {
    serviceInformation: {
      type: Object,
      required: true,
    },
  },
}
</script>
